module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-7MFLSHXLFZ","cookieName":"gatsby-gdpr-cookies","anonymize":true,"allowAdFeatures":false},"facebookPixel":{"pixelId":"835526337410097","cookieName":"gatsby-gdpr-cookies"},"hotjar":{"hjid":"2951898","hjsv":"6","cookieName":"gatsby-gdpr-cookies"},"linkedin":{"trackingId":"5161825","cookieName":"gatsby-gdpr-cookies"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"matomo","matomoUrl":"https://customsurgical.co/matomo/js/container_NwRTKZtf.js","siteUrl":"https://customsurgical.co"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Custom Surgical","short_name":"Custom Surgical","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/iso_color.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3ee8e417441122a7e3cfa28f244b5a54"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
