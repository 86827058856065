import React, { useEffect, useState } from "react";
import getIP from "./get-ip";
// import useCountryLocation from './use-country-location';

export const myContext = React.createContext();

const Provider = (props) => {
  //   const { countryInit, error } = useCountryLocation(getIP);
  const [country, setCountry] = useState("");
  const [ecommerceDollar, setEcommerceDollar] = useState(false);
  useEffect(() => {
    getIP()
      .then((res) => {
        setCountry(res);
      })
      .catch((e) => setCountry("DE"));

    if (country == "US" || country == "PR" || country == "CA")
      setEcommerceDollar(true);
  });

  return (
    <myContext.Provider
      value={{
        country,
        setCountry,
        ecommerceDollar,
        setEcommerceDollar,
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};

export default ({ element }) => <Provider>{element}</Provider>;
